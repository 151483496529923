import { Link as RouterLink } from "react-router-dom";
import React, { useState } from 'react';
import { Avatar as ChakraAvatar, HStack } from "@chakra-ui/react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,

  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Img,
  SimpleGrid,
  Center,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

import Img9 from '../../assets/9.png'
import Img12 from '../../assets/12.jpeg'
import Img1 from '../../assets/1.png'
import Img5 from '../../assets/5.png'
import Img7 from '../../assets/7.png'
import Img6 from '../../assets/6.png'
import Img4 from '../../assets/4.png'
import { useLogout } from '../../hooks/auth';
import {USERS, DASHBOARD, HOME, CHAT, LOGIN,PROTECTED,PROFILE, ACCOUNT } from '../../lib/routes';
import Img2  from "../../assets/INFINITE X DOMAIN Logo.png";
import { useEffect } from "react";
import { useAuth } from "../../hooks/auth";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import Avatar from "../profile/Avatar";
export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const { logout,} = useLogout();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, isLoading,overrideAvatar = null } = useAuth();

  const MenuItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);

    const handleItemClick = () => {
      setDropdown(false);
    };
  
    const toggleDropdown = () => {
      setDropdown(!dropdown);
    };
  
    return (
      <Box
      bgColor={"grey"}

      >
      <Button  _hover={{
        
        bg:"transparent",

    
      }} backgroundColor={'transparent'} onClick={toggleDropdown} >

       <ChakraAvatar size={"lg"} src={overrideAvatar || user.avatar} />
        {dropdown && (
          <HStack 
   

          className="dropdown">
          <Button 
          
          _hover={{
        
        bg:"transparent",

    
      }}
           backgroundColor={"grey"}  as={RouterLink}   width={'5'}    
  to={`${PROTECTED}/profile/${user.id}`} fontWeight="bold" onClick={handleItemClick}> <ChakraAvatar size={"lg"} src={Img6} as={RouterLink} 
  to={`${PROTECTED}/profile/${user.id}`} fontWeight="bold" onClick={handleItemClick}> </ChakraAvatar> </Button>



          <div></div>

          
          <Button
           _hover={{
        
        bg:"transparent",

    
      }}
width={'5'}      
      backgroundColor={"grey"} 
         as={RouterLink} 
         to={CHAT} fontWeight="bold"
         bgImg={Img5}
   
          onClick={handleItemClick}


        > <ChakraAvatar size={"lg"} src={Img5} as={RouterLink} 
         to={CHAT} fontWeight="bold" /></Button>

<Button
           _hover={{
        
        bg:"transparent",

    
      }}
width={'5'}      
      backgroundColor={"grey"} 
         as={RouterLink} 
         to={CHAT} fontWeight="bold"
         bgImg={Img5}
   
          onClick={handleItemClick}


        > <ChakraAvatar size={"lg"} src={Img5} as={RouterLink} 
         to={CHAT} fontWeight="bold" /></Button>

        




        <Button 
              
           _hover={{
        
        bg:"transparent",

    
      }}
      
      bgColor={"transparent"}
         as={RouterLink}onClick={logout}  fontWeight="bold" width={'5'}    >
<Text fontSize={'md'}> LOGOUT</Text>
        </Button>
          </HStack>
          
        )}
      </Button>
      </Box>
    );
  };


  useEffect(() => {
    
    if (!isLoading && pathname.startsWith("/protected") && !user) {
      navigate(LOGIN);
    }
  }, [pathname, user, isLoading]);

  if (isLoading) return "Loading auth user...";


  return (
    
      <HStack
       bgColor={"grey"}
        rounded={'full'}
        minH={'60px'}
        py={{ base: 2 }}
        pos={"fixed"}
        zIndex={2}
        shadow={"lg"}
        opacity={"1"}
        w={"full"}
        justifyContent={"center"}
    
       
        px={{ base: 30 }}
        borderBottom={1}
        borderTop={1}
        borderStyle={'solid'}
        borderColor={"teal"}
        align={'center'}>
      
              <Link  as={RouterLink} to={HOME} fontWeight="bold">
         <ChakraAvatar size={"lg"} src={Img6} as={RouterLink} 
         to={HOME} fontWeight="bold" />
        </Link>

        <Link  as={RouterLink} to={HOME} fontWeight="bold">
           <ChakraAvatar size={"lg"} src={Img7} as={RouterLink} 
         to={HOME} fontWeight="bold" />
        </Link>

        <MenuItems/>

        <Link  as={RouterLink} to={HOME} fontWeight="bold">
         <ChakraAvatar size={"lg"} src={Img9} as={RouterLink} 
         to={USERS} fontWeight="bold" />
        </Link>

        <Link  as={RouterLink} to={HOME} fontWeight="bold">
         <ChakraAvatar size={"lg"} src={Img4} as={RouterLink} 
         to={DASHBOARD} fontWeight="bold" />
        </Link>

        </HStack>

  );
}




