import { Box, Text } from "@chakra-ui/react";
import Header from "./Header";
import Actions from "../../components/post/Actions";

export default function Post({ post }) {
  const { text } = post;

  return (
    <Box backgroundColor={"black"} p="2" maxW="600px" textAlign="left">
      <Box backgroundColor={"gray"} border="2px solid" borderColor="gray.100" borderRadius="md">
        <Header post={post} />

        <Box p="2" minH="100px">
          <Text color={"black"}  wordBreak="break-word" fontSize="lg">
            {text}
          </Text>
        </Box>

        <Actions post={post} />
      </Box>
    </Box>
  );
}
