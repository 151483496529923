import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { DASHBOARD, LOGIN, HOME,FIRST } from "../../lib/routes";
import { Link as RouterLink } from "react-router-dom";
import { useRegister } from "../../hooks/auth";
import { useForm } from "react-hook-form";
import {
  emailValidate,
  usernameValidate,
  nameValidate,
} from "../../utils/form-validate"
import Img from '../../assets/INFINITE X DOMAIN Logo.png'
import Img2 from '../../assets/12.jpeg'
export default function Register() {
  const { register: signup, isLoading } = useRegister();
  
 

  const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
        confirmpin: Yup.number()
        .required('Pin is required')
        .oneOf([75656,// 12345,
 //49291, //51443, //66227, //84396, //96153
,//16203, //37192, //46109, //60448, //94201
,//22483, //37958, //59445, //69840, //92556
,//31894, //48400, //58787, //59198, //71020
,//35863, //53837, //54500, //59024, //96001
,//15375, //17044, //17614, //43117, //80832
,//39739, //43007, //65420, //78013, //99595
,//15019, //45050, //70739, //91188, //97053
,//13598, //51891, //70783, //74580, //91042
,//16667, //44933, //55377, //67432, //92464
,//16261, //34227, //41553, //85878, //99947
,//39724, //46408, //51870, //71568, //91478
,//19525, //26166, //48767, //60533, //78960
,//16415, //25139, //60463, //65730, //94805
,//26529, //54981, //64478, //68247, //80180
,//26818, //30099, //93736, //93846, //99132
,//22784, //41582, //59195, //73352, //84995
,//32423, //33412, //49515, //82580, //98565
,//12506, //28441, //37706, //72751, //87202
,//55407, //69561, //79371, //79646, //94730
,//31112, //34437, //35949, //49339, //71188
,//28974, //55018, //61387, //61451, //95446
,//22578, //39373, //44781, //71716, //73038
,//31115, //38551, //67673, //92151, //97656
,//12706, //53195, //64728, //68202, //79978
,//12247, //45782, //58561, //77725, //78098
,//40634, //64449, //84093, //84331, //96263
,//24117, //24561, //75187, //85745, //94978
,//20570, //34937, //49203, //63329, //71019
,//13411, //24204, //32240, //38105, //42519
,//29234, //30490, //58899, //72295, //85889
,//31607, //62917, //69633, //78054, //96300
,//11157, //27198, //37790, //69139, //76110
,//46523, //56378, //58038, //64266, //96274
,//69495, //69517, //79919, //84518, //91659
,//35151, //39582, //59483, //61348, //96964
,//25141, //38798, //57574, //78655, //99694
,//12031, //15191, //51432, //59149, //94391
,//29859, //62686, //77325, //79063, //88979
,//44375, //54827, //58182, //60411, //98049
,//63728, //73717, //77454, //89685, //97101
,//17144, //38170, //43330, //48883, //99368
,//29557, //46014, //53213, //72828, //86379
,//36027, //42888, //60599, //75590, //88887
,//29960, //53795, //59690, //64027, //88961
,//10761, //10865, //18129, //71309, //97068
,//10826, //26494, //59812, //85485, //85991
,//15677, //43488, //51926, //63479, //85182
,//16764, //24146, //76110, //89525, //96499
,//20136, //25079, //50421, //64782, //81467
,//12292, //14515, //15965, //43230, //57119
,//20387, //39665, //41025, //72895, //73718
,//20142, //33788, //42328, //74339, //96979
,//14813, //46130, //64806, //71413, //93630
,//22895, //25474, //67894, //72650, //94620
,//51734, //66344, //67362, //71311, //92629
,//18219, //41991, //49683, //54459, //85636
,//10160, //34541, //54168, //64714, //76509
,//24625, //25110, //27665, //43794, //47061
,//16054, //24615, //70104, //72193, //98739
,//33095, //51320, //65118, //68272, //81941
,//19908, //20179, //56713, //60320, //95177
,//11830, //19318, //78619, //98124, //98725
,//34319, //37989, //41343, //62130, //84753
,//43458, //63950, //68353, //86849, //88286
,//16450, //31733, //52831, //78796, //96050
,//41661, //47828, //52908, //82142, //92220
,//20336, //32822, //69414, //88810, //90247
,//37743, //47183, //88121, //88347, //92945
,//23382, //63828, //66605, //80283, //82607
,//16315, //17946, //32849, //76463, //91929
,//39437, //64308, //76733, //81054, //83932
,//14026, //18436, //25995, //52806, //87845
,//13436, //18715, //69513, //74101, //83061
,//25527, //72032, //82711, //88276, //95276
,//15553, //22863, //52425, //80253, //83970
,//13059, //24520, //38366, //82129, //96190
,//18685, //22108, //75494, //76034, //90911
,//35043, //44004, //58906, //65020, //72311
,//62635, //63368, //74566, //75146, //94521
,//26872, //40912, //67640, //74405, //90881
,//13369, //33091, //42618, //43941, //92600
,//25739, //31163, //36545, //45168, //95860
,//33473, //52137, //55983, //76987, //87148
,//45669, //47315, //59038, //65834, //69096
,//24869, //42379, //66604, //94255, //97363
,//13690, //46838, //62888, //73823, //93661
,//34691, //39606, //47026, //69290, //84210
,//15654, //21100, //39665, //44903, //57510
,//27804, //71505, //75048, //75921, //98356
,//27810, //63998, //66822, //72790, //77193
,//14937, //34073, //44738, //83052, //90232
,//16225, //20088, //22178, //38543, //83750
,//14839, //18445, //30376, //70684, //78605
,//53335, //57694, //62773, //67867, //98682
,//21643, //45469, //47916, //87984, //89731
,//15690, //22653, //38033, //43119, //73706
,//21951, //23286, //49200, //62774, //80487
,//11767, //28499, //68600, //82706, //95717
,// 23087,// 36692,// 58459,// 68684,// 84740
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        ], 'Text ')
        
                    
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
      register,
      handleSubmit,
      reset,
      formState,
    } = useForm(formOptions);
    const { errors } = formState;

  async function handleRegister(data) {
    signup({
      username: data.username,
      email: data.email,
      password: data.password,
      comfirm: data.confirm,
      redirectTo: FIRST,
    });
  }
  

  return (
    <Center w="100%" h="100vh" bgImg={Img2} objectFit="cover" opacity="0.8"position="center center" style={{
      
      width: 'max',
      height: 'max'
    }}>
      <Box mx="1" maxW="md"backgroundColor= 'grey' p="9" borderWidth="1px" borderRadius="lg">
      <img src= {Img} alt="pic"></img>
        <Heading mb="4" size="lg" textAlign="center">
        
          Register

        </Heading>

        <form onSubmit={handleSubmit(handleRegister)}>
          <FormControl isInvalid={errors.username} py="2">
            <FormLabel>Username</FormLabel>
            <Input
              placeholder="Username"
              color='black'
  _placeholder={{ color: 'white' }}
              {...register("username", usernameValidate)}
            />
            <FormErrorMessage>
              {errors.username && errors.username.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.email} py="2">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="user@email.com"
              color='black'
  _placeholder={{ color: 'white' }}
              {...register("email", emailValidate)}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          

        

          <FormControl isInvalid={errors.password} py="2">
          <FormLabel>Password</FormLabel>
                            <Input name="password" type="password"
                            placeholder='Password'
                            color='black'
  _placeholder={{ color: 'white' }}
                            
                             {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.password?.message}</div>
          </FormControl>
          <FormControl isInvalid={errors.password} py="2">
            

            <FormLabel>Confirm Password</FormLabel>
                            <Input name="confirmPassword" type="password" 
                            placeholder='Confirm Password'
                            color='black'
  _placeholder={{ color: 'white' }}
                            {...register('confirmPassword')} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
          </FormControl>
          <Button
            mt="4"
            type="submit"
            colorScheme="blackAlpha"
            size="md"
            w="full"
            isLoading={isLoading}
            loadingText="Signing Up"
          >
            Register
          </Button>
          <FormControl isInvalid={errors.password} py="2">
          <FormLabel>Pin</FormLabel>
                            <Input name="pin" type="pin"
                            placeholder='Access Code'
                            color='black'
  _placeholder={{ color: 'white' }}
                            
                             {...register('confirmpin')} className={`form-control ${errors.confirmpin ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.confirmpin?.message}</div>
          </FormControl>
        </form>

        <Text fontSize="xlg" align="center" mt="6">
          Already have an account?{" "}
          <Link
            as={RouterLink}
            to={LOGIN}
            color="blackAlpha.800"
            fontWeight="medium"
            textDecor="underline"
            _hover={{ background: "blackAlpha.100" }}
          >
            Log In
          </Link>{" "}
        
        </Text>
      </Box>
    </Center>
  );
}
