import {



  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { DASHBOARD, REGISTER, HOME } from "../../lib/routes";
import { Link as RouterLink } from "react-router-dom";
import { useLogin } from "../../hooks/auth";
import { useForm } from "react-hook-form";
import { emailValidate, passwordValidate } from "../../utils/form-validate";
import Img from '../../assets/12.jpeg'
import Img2 from '../../assets/INFINITE X DOMAIN Logo.png'
import Dashboard from "../dashboard";

export  function Login2({isOpen, onClose}) {
  const { login, isLoading } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function handleLogin(data) {
    login({
      email: data.email,
      password: data.password,
      redirectTo: HOME,
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent opacity={"0.3"}>
 
    
    <Center position={"absolute"} paddingTop={'300'}  w="100%" h="100%" backgroundColor="transparent" style={{
backgroundColor: 'transparent',
      width: 'max',
      height: 'max',
    }}>
      <Box  
      
      mx="1" maxW="4xl"   backgroundColor= "black" opacity={1} p="9" borderWidth="1px" borderRadius="lg">
        
        <Heading mb="4" size="lg" textColor="white" textAlign="center">

					
          Log In
          
        </Heading>

        <form onSubmit={handleSubmit(handleLogin)}>
          <FormControl isInvalid={errors.email} py="2">
            <FormLabel textColor="white">Email</FormLabel>
            <Input
              type="email"
              placeholder="user@email.com"
              {...register("email", emailValidate)}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password} py="2">
            <FormLabel textColor={"white"}>Password</FormLabel>
            <Input
              type="password"
              placeholder="password123"
              {...register("password", passwordValidate)}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            mt="4"
            type="submit"
            colorScheme="gray"
            size="md"
            w="full"
            isLoading={isLoading}
            loadingText="Logging In"
          >
            Log In
          </Button>
        </form>
       


      </Box>
    </Center>
 
    </ModalContent>
    </Modal>
   

    
  );
}
