import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, query, where, orderBy, onSnapshot, or, and } from '@firebase/firestore';
import { Box } from "@chakra-ui/react";
import { Button, Code, VStack } from "@chakra-ui/react";
import { useUsers } from "../../../../hooks/users";
import User from "../../User";
import {

  FormControl,
  Stack,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Flex,
  SimpleGrid,
  Link,
  Text,
  Container,
} from "@chakra-ui/react";
export default function Search() {
    const [data, setData] = useState('');
    const [search, setSearch] = useState('');
    const { users, isLoading } = useUsers();
    const [sortType, setSortType] = useState("ascending");
    const [sortByField, setSortByField] = useState("title");
    const [filter, setFilter] = useState("");

    const searchFilters = (e) => {
      e.preventDefault();
      const collectionRef = collection(db, "users");
      const q = query(collectionRef, where("type", "==", `${search}`),
  
    );
    };
          const searchRecords = (e) => {
        e.preventDefault();
        const collectionRef = collection(db, "users");
        const q = query(collectionRef, where("username", "==", `${search}`),
        );

        const unsub = onSnapshot(q, (snapshot) => {
            setData(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, key: doc.id })));
        });
      
  

        return unsub;
    };

    return (
        <div>
            <FormLabel color={'white'}>Search By Name </FormLabel>

            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Search by name"
                defaultValue="default"
                onKeyUp={searchRecords}
                onChange={(e) => setSearch(e.target.value)}
            />
              <div>

   </div>       

    
    {data && (
                    <>
                        {data.map((user, index) => (
                            <User key={user.id} user={user} />
                        
                        ))}
                    </>
                )}

          
        </div>
    );
    
}
