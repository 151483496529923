import { createBrowserRouter } from "react-router-dom";

import Login from "../components/auth/Login";
import First from "../components/auth/firstsetup";
import Register from "../components/auth/Register";
import Layout from "../components/layout";
import Dashboard from "../components/dashboard";
import Comments from "../components/comments";
import Profile from "../components/profile";
import Edit from "../components/profile/EditProfile";
import Users from "../components/users";
import Home from "../components/dashboard/index";
import Account from "../components/dashboard/mem2";
import Idcard from "../components/profile/idindex"
import Idcardf from "../components/profile/ixdcard"
import Chat from "../components/chat/chat"
export const ROOT = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";

export const PROTECTED = "/protected";
export const FIRST = "/protected/components/auth/firstsetup";
export const DASHBOARD = "/protected/dashboard";
export const USERS = "/protected/users";
export const PROFILE = "/protected/profile/:id";
export const EDIT = "/protected/profile/EditProfile";
export const COMMENTS = "/protected/comments/:id";
export const HOME = "/protected/dashboard/index";
export const ACCOUNT = "/protected/dashboard/mem2";
export const IDCARD = "/protected/profile/idindex";
export const IDCARDF = "/protected/profile/ixdcard";
export const CHAT = "/protected/components/chat/chat"
export const router = createBrowserRouter([
  { path: ROOT, element: <Login />},
  { path: LOGIN, element: <Login /> },
  { path: REGISTER, element: <Register /> },
  {
    path: PROTECTED,
    element: <Layout />,
    children: [
      {
        path: DASHBOARD,
        element: <Dashboard />,
      },
     
      {
        path: HOME,
        element: <Home/>,
      },
      {
        path: USERS,
        element: <Users />,
      },
      {
        path: PROFILE,
        element: <Profile />,
      },
      {
        path: ACCOUNT,
        element: <Account />,
      },
      {
        path: COMMENTS,
        element: <Comments />,
      },
      {
        path: IDCARD,
        element: <Idcard />,
      },
   
    {
      path: IDCARDF,
      element: <Idcardf />,
    },
    {
      path: EDIT,
      element: <Edit/>,
    },
    {
      path: CHAT,
      element: <Chat />,
    },
    {
      path: FIRST,
      element: <First/>,
    },
  ],
  },
]);
