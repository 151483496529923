import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import AuthProvider from "./context/auth";
import PrivateRoute from "./components/PrivateRoute";
import { Container, Box } from "@chakra-ui/react";

function Chat() {
  return (
    <Box>

   
     <Home/>
     </Box>
  );
}

export default Chat;
