import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, query, where, orderBy, onSnapshot, or, and } from '@firebase/firestore';
import { Box } from "@chakra-ui/react";
import { Button, Code, VStack } from "@chakra-ui/react";
import { useUsers } from "../../../../hooks/users";
import User from "../../User";
import {

  FormControl,
  Stack,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Flex,
  SimpleGrid,
  Link,
  Text,
  Container,
} from "@chakra-ui/react";
export default function Search() {
    const [data, setData] = useState('');
    const [search, setSearch] = useState('');
    const { users, isLoading } = useUsers();
    const [sortType, setSortType] = useState("ascending");
    const [sortByField, setSortByField] = useState("title");
    const [setFilter] = useState("");

   
          const searchRecords = (e) => {
        e.preventDefault();
        const collectionRef = collection(db, "users");
        const q = query(collectionRef, where("type", "==", `${search}`),
        );

        const unsub = onSnapshot(q, (snapshot) => {
            setData(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, key: doc.id })));
        });
        

        return unsub;
    };

    return (
        <div>
        
        <FormControl>
        <FormLabel color={'white'}>Search By Type </FormLabel>
  <div>
  <select      onClick={searchRecords}
                onChange={(e) => setSearch(e.target.value)}
  >
  <option value="unknown"></option>
     <option value="music">music</option>
     <option value="business">BUSINESS</option>
     <option value="entertainment">ENTERTAINMENT</option>
   </select>

   </div> 
   </FormControl>
  
    {data && (
                    <>
                        {data.map((user, index) => (
                            <User key={user.id} user={user} />
                        
                        ))}
                    </>
                )}
         
        </div>

    );
    
}
