export const usernameValidate = {
  required: {
    value: true,
    message: "Please enter username",
  },
  minLength: {
    value: 6,
    message: "Username must be at least 6 characters long",
  },
  pattern: {
    value: /^[a-zA-Z0-9]+$/,
    message: "Username must be alphanumeric",
  },
};
export const pinValidate = {
  required: {
    value: true,
    message: "Please enter Code",
  },
  minLength: {
    value: 6,
    message: "5 digit code found in your invitation",
  },
  pattern: {
    value: /^[0-9]+$/,
    message: "Numbers Only",
  },
};

export const emailValidate = {
  required: {
    value: true,
    message: "Please enter an email address",
  },
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Email address is not valid",
  },
};
export const nameValidate = {
  required: {
    value: true,
    message: "Required",
  },
  
};

export const passwordValidate = {
  required: {
    value: true,
    message: "Please enter password",
  },
  minLength: {
    value: 6,
    message: "Password must be at least 6 characters long",
  },
};
export const confirmValidate = {
  required: {
    value: true,
    message: "Please confirm password",
  },

  confirm: {
    value: 8,
    message: "Passwords must matchz",
  },
};
