

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/users";
import { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  FormControl,
  Stack,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Flex,
  SimpleGrid,
  Link,
  Text,
  Container,
  useDisclosure,
} from "@chakra-ui/react";

import { pickBy } from 'lodash';
import { DASHBOARD, LOGIN, HOME,FIRST } from "../../lib/routes";
import { Link as RouterLink } from "react-router-dom";
import { useRegister } from "../../hooks/auth";
import { useForm } from "react-hook-form";
import EditProfile from "../profile/EditProfile";
import {
  emailValidate,
  usernameValidate,
  nameValidate,
} from "../../utils/form-validate"
import Img from '../../assets/INFINITE X DOMAIN Logo.png'
import Img2 from '../../assets/12.jpeg'
import React, { useRef } from 'react';
import { doc, setDoc, updateDoc, } from '@firebase/firestore'
import { useState } from 'react';
import DASHI from '../../assets/dash.png'
import { useAuth } from "../../hooks/auth";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { auth, db, } from "../../lib/firebase";
import { Timestamp } from "firebase/firestore";
import { green } from '@mui/material/colors';
export default function Account() {
    
    const { pathname } = useLocation();
    const navigate = useNavigate();
const [authUser, ] = useAuthState(auth);
const { isOpen, onOpen, onClose } = useDisclosure();
const userRef = useRef();
const idRef = useRef();
const nameRef = useRef();
const numberRef = useRef();
const typeRef = useRef();
const emailRef = useRef();
const passwordRef = useRef();
const bemailRef = useRef();
const bnumberRef = useRef();
const bcnameRef = useRef();
const bcnumberRef = useRef();
const hoursRef = useRef();
const locationRef = useRef();
const addressRef = useRef();
const createdAt = Timestamp.fromDate(new Date());
const isOnline = true;
const { user, isLoading } = useAuth();
const ref = doc(db, "users",  authUser.uid);




  const handleSubmit = async (submit) => {
    
    submit.preventDefault(); 

    let data = {
      username:userRef.current.value,
      name:nameRef.current.value,
      number:numberRef.current.value,
      id:idRef.current.value,
      type:typeRef.current.value,
      email:emailRef.current.value,
      password:passwordRef.current.value,
      bemail:bemailRef.current.value,
      uid:idRef.current.value,
      bcname:bcnameRef.current.value,
      bcnumber:bcnumberRef.current.value,
      hours:hoursRef.current.value,
      location:locationRef.current.value,
      createdAt: Timestamp.fromDate(new Date()),
      isOnline: true,
      address:addressRef.current.value,
    }

    try {
      await setDoc(ref, data)
    } catch (submit) {
      console.log(submit);
    }
  } 
  useEffect(() => {
    if (!isLoading && pathname.startsWith("/protected") && !user) {
      navigate(LOGIN);
    }
  }, [pathname, user, isLoading]);

  if (isLoading) return "Loading Back Of Card";
  return(
    <Flex 
    
    w={'full'}
    h={'100vh'}
    backgroundImage={DASHI}
    backgroundSize={'cover'}
    backgroundPosition={'center center'}>
   
         <Container pt={'10'}>
        <form position="center"  onSubmit={handleSubmit}>
        <Heading position={'relative'}  size="lg" textAlign="center" color={"white"}>
          
          ACCOUNT INFO
  
          </Heading>
      
          <Button
            pos="absolute"
            mb="2"
            top="75"
            size={"sm"}
            right="6"
            colorScheme="teal"
            onClick={onOpen}
          >
            Change avatar
          </Button>
        
   <Stack maxW={'2xl'} align={'flex-start'} spacing={6}></Stack>
   <EditProfile isOpen={isOpen} onClose={onClose} />
   
    <SimpleGrid justify="space-between"  Img={Img2} back mx="auto" po  columns={[1, 1, 2]} spacing={[4, 2]}>
    
  <FormControl>
  <FormLabel color={'white'}>CONTACT NAME</FormLabel>
  <Input    defaultValue={user.name} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={nameRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>CONTACT NUMBER</FormLabel>
  <Input    value={user.number} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={numberRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>SUPPORT ID</FormLabel>
  <Input    value={user.id} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={idRef}/>
  </FormControl>

  <FormControl>
  <FormLabel color={'white'}>EMAIL</FormLabel>
  <Input    defaultValue={user.email} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={emailRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>PASSWORD</FormLabel>
  <Input    value="***********" 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={passwordRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'green'}>MEMBERSHIP</FormLabel>
  <Button
              backgroundColor={user.color}>
              </Button>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>BILLING</FormLabel>
  <Button 
              color='white'></Button>
  </FormControl>
  </SimpleGrid>
    </form>
    <form position="center"  onSubmit={handleSubmit}>
        <Heading  position={'relative'} size="lg" textAlign="center" color={"white"}>
          
          BUSINESS INFO
  
          </Heading>
        
   <Stack maxW={'2xl'} align={'flex-start'} spacing={6}></Stack>
   
    <SimpleGrid justify="space-between" Img={Img2} back mx="auto" po  columns={[1, 1, 2]} spacing={[4, 2]} >
    <FormControl>
    
    <FormLabel color={'white'}>STAGE/BUSINESS NAME</FormLabel>
    <Input  colorScheme='gray'  defaultValue={user.username} 
                color='white'
    _placeholder={{ color: 'white' }}type="text"  ref={userRef}/>
    </FormControl>
    <FormControl>
    
  <FormLabel color={'white'}>BUSINESS TYPE </FormLabel>
  <div>
  <select ref={typeRef} defaultValue={user.type}
  >
     <option value="music">MUSIC</option>
     <option value="business">BUSINESS</option>
     <option value="entertainment">ENTERTAINMENT</option>
   </select>

   </div>       
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>CONTACT NAME</FormLabel>
  <Input    defaultValue={user.bcname} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={bcnameRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>CONTACT NUMBER</FormLabel>
  <Input    defaultValue={user.bcnumber} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={bcnumberRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>BUSINESS EMAIL</FormLabel>
  <Input    defaultValue={user.bemail} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={bemailRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>HOURS OF OPERATION</FormLabel>
  <Input    defaultValue={user.hours} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={hoursRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>LOCATION</FormLabel>
  <Input    defaultValue={user.location} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={locationRef}/>
  </FormControl>
  <FormControl>
  <FormLabel color={'white'}>BUSINESS ADDRESS</FormLabel>
  <Input    defaultValue={user.address} 
              color='white'
  _placeholder={{ color: 'white' }}type="text"  ref={addressRef}/>
   <Button type="submit">Save</Button>
  </FormControl>
  </SimpleGrid>
    </form>
    
</Container>
    
    </Flex>
  );
}

  

  
  
  

