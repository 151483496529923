import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore'; // For Firestore
import { getDatabase } from 'firebase/database'; // For Realtime Database



const firebaseConfig = {
  apiKey: "AIzaSyC7EkRWx9kn5KThDZCv3sIPF2gqlci2POo",
  authDomain: "infinitexdomain.firebaseapp.com",
  projectId: "infinitexdomain",
  storageBucket: "infinitexdomain.appspot.com",
  messagingSenderId: "756028379668",
  appId: "1:756028379668:web:4b1f283be33a13484ccd01",
  measurementId: "G-81PWVJS47W",
};


const app = initializeApp(firebaseConfig);
 const db = getFirestore(app);
 const database = getDatabase(app)
 const auth = getAuth(app);
 const storage = getStorage(app);
 export {db, database, auth, storage}