import { IDCARD, LOGIN,ACCOUNT,CHAT } from "../../lib/routes";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import Navbar from "../../components/layout/Navbar";
import { Box, Flex,Text,Stack, Heading, Img, Link } from "@chakra-ui/react";
import User from "../users/User";
import { SimpleGrid } from "@chakra-ui/react";
import { useUsers } from "../../hooks/users";
import { Button,} from "@chakra-ui/react";
import { Code, HStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { USERS } from "../../lib/routes";
import { DASHBOARD } from "../../lib/routes";
import { PROTECTED,} from "../../lib/routes";
import { LANDING } from "../../lib/routes";
import { Link as RouterLink } from "react-router-dom";
import Img2 from '../../assets/12.jpeg'
import DASHI from '../../assets/dash.png'
import BackgroundVideo from "../../assets/backgroundvideo";
import vid12 from "../../assets/video-2.mp4"
import Home from "../users/search/pages/Home";
import ButtonFilter from "../users/search/pages/ButtonFilter";
import Lsers from "./Lsers";
export default function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const [search, setSearch] = useState("");
  const { users,} = useUsers();
  const { id, username } = useUsers;
  const handleSubmit = (e) => {
    e.preventDefault();
   navigate(`/Search?${search}`);
    setSearch(`${PROTECTED}/profile/${id}`);
  };

  useEffect(() => {
    if (!isLoading && pathname.startsWith("/protected") && !user) {
      navigate(LOGIN);
    }
  }, [pathname, user, isLoading]);

  if (isLoading) return "Loading auth user...";

  return (
    <>
   <Stack
          flex={{ base: 100, md: 0 }}
          justify={'center'}
          direction={'row'}
          spacing={2}
          paddingTop={2}
         >
<Home/>
<ButtonFilter/>
     </Stack>

 <Flex
         bgColor={"grey"}
        color={"white"}
      
        minH={'60px'}
        py={{ base: 2 }}
        pos={"absolute"}
        shadow={"lg"}
        opacity={"1"}
        w={"full"}
      
    
       
        px={{ base: 30 }}
        align={'center'}
      backgroundImage={DASHI}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}>
     <Stack maxW={'2xl'} align="center" spacing={6}></Stack>
      <SimpleGrid justify="space-between" Img={Img2} back mx="auto" po  columns={[1, 1, 1]} spacing={[2, 2]}  py="center">

       

      <Lsers/>
     

      
    </SimpleGrid>
    </Flex>
</>
  );
}
