import {
  Button,
  Divider,
  Flex,
  Box,
  VStack,
  useBreakpointValue,
  HStack,
  Container,
  Stack,
  Text,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import LOG from "../../assets/LOG.png";

import PostsList from "../../components/post/PostsList";
import { usePosts } from "../../hooks/posts";
import { useUser } from "../../hooks/users";
import { useParams } from "../../assets/LOG.png";

import { format } from "date-fns";
import { Login2 } from "./Login2";

import { useAuth } from "../../hooks/auth";

  export default function Login() {

  const { isOpen, onOpen, onClose } = useDisclosure();



  return (
    <Flex
      w={'full'}
      h={'100vh'}
      backgroundImage={LOG}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}>
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
          <Button  
         
         height="100%"
         width="30%"
        top="0"
        botton="0"
        zIndex={2}
        left="0"
        right="0"
        objectFit="cover"
      margin="auto"
            position= "absolute"
  
    justifyContent= "center"
    alignItems= "center"
            colorScheme="transparent"
            onClick={onOpen}
          >

          </Button>
  
        <Login2 isOpen={isOpen} onClose={onClose} />
      <Divider />
    
        </Stack>
      </VStack>
    </Flex>
  );
}
